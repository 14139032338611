import { Injectable } from '@angular/core';
import { ITokenRequest } from '../../../core/interfaces/token-request.interface';
import { map, Observable, tap } from 'rxjs';
import { IToken } from '../../../core/interfaces/token.interface';
import { AuthTokenService } from './auth-token.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ICredentials } from '../../../core/interfaces/credentials.interface';
import { ISignOutRequest } from '../../../core/interfaces/sign-out-request.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private pcName = 'Atla 4.0.0.0';

  constructor(
    private http: HttpClient,
    private authTokenService: AuthTokenService
  ) {}

  signIn(credentials: ICredentials): Observable<IToken> {
    const request: ITokenRequest = this.fromCredentials(credentials);

    return this.http.post<IToken>(environment.tokenEndpoint, JSON.stringify(request)).pipe(
      map((response: IToken) => {
        const jwt = response.token;
        const refresh = response.refreshToken;
        this.saveRefreshToken(jwt, refresh, request.username);
        return response;
      })
    );
  }

  fromCredentials(creds: ICredentials): ITokenRequest {
    return {
      email: creds.username,
      username: creds.username,
      password: creds.password,
      appName: environment.appName,
      pcName: this.pcName,
    };
  }

  saveRefreshToken(jwt: string, refreshToken: string, username: string) {
    this.authTokenService.saveNewSession(jwt, refreshToken, username);
  }

  signOut() {
    const request: ISignOutRequest = this.toSignoutRequest();

    return this.http.post<Response>(environment.signOutEndpoint, JSON.stringify(request)).pipe(
      tap(() => {
        this.revokeToken();
      })
    );
  }

  toSignoutRequest(): ISignOutRequest {
    const pcName = this.pcName;
    return {
      appName: environment.appName,
      pcName: pcName,
    };
  }

  revokeToken(): void {
    this.authTokenService.revoke();
  }

  isLoggedIn() {
    return this.authTokenService.valid();
  }
}
