<div class="flex flex-col items-center pt-8">
  <img src="assets/images/atla-logo.png" width="256" alt="Oseberg Logo" />

  <h3 class="mb-2 mt-3 text-[34px]">oseberg</h3>
  <div class="mb-6 text-[20px]">Atla</div>

  <ng-container [formGroup]="loginForm">
    <div *ngIf="loginForm.get('username') as control" class="form-control mb-4 w-full max-w-xs">
      <input
        formControlName="username"
        type="text"
        placeholder="Username"
        class="input input-bordered w-full max-w-xs"
        (keydown.enter)="signIn()" />
      <div *ngIf="control.touched && control.invalid" class="mt-0.5 text-sm text-error">
        Username is required
      </div>
    </div>

    <div *ngIf="loginForm.get('password') as control" class="form-control w-full max-w-xs">
      <input
        formControlName="password"
        type="password"
        placeholder="Password"
        class="input input-bordered w-full max-w-xs"
        (keydown.enter)="signIn()" />

      <div class="mt-0.5 flex w-full max-w-xs justify-between">
        <div [class.invisible]="!(control.touched && control.invalid)" class="text-sm text-error">
          Password is required
        </div>

        <a class="link link-secondary text-sm no-underline">Reset Password</a>
      </div>
    </div>
  </ng-container>

  <div class="form-control mb-2 w-full max-w-xs">
    <label class="label w-fit cursor-pointer">
      <input
        type="checkbox"
        checked="checked"
        [(ngModel)]="shouldRememberUsername"
        (change)="shouldRememberUsernameChange()"
        class="checkbox-secondary checkbox checkbox-sm me-2" />
      <span class="label-text">Remember Username</span>
    </label>
  </div>

  <div
    role="alert"
    class="alert alert-error mb-4 w-full max-w-xs text-white"
    [class.invisible]="!error">
    <i class="fa-regular fa-circle-xmark shrink-0 stroke-current"></i>
    <span>{{ error }}</span>
  </div>

  <div class="flex justify-center">
    <button
      class="btn btn-accent ms-3 w-36 text-white"
      [disabled]="loginForm.invalid"
      (click)="signIn()">
      LOGIN
    </button>
    <button class="btn btn-secondary ms-3 w-36 text-white" (click)="cancel()">CANCEL</button>
  </div>
</div>
