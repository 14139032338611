import { Injectable } from '@angular/core';
import { ToastType } from '../../features/shared/components/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: { message: string; type: ToastType }[] = [];

  success(message: string) {
    this.showToast(message, 'success');
  }

  error(message: string) {
    this.showToast(message, 'error');
  }

  private showToast(message: string, type: ToastType = 'success') {
    const toast = { message, type };

    this.toasts.push(toast);

    setTimeout(() => {
      this.hideToast(this.toasts.indexOf(toast));
    }, 3000);
  }

  private hideToast(index: number) {
    if (index !== -1) {
      this.toasts.splice(index, 1);
    }
  }
}
