import { Component, Input } from '@angular/core';

export type ToastType = 'success' | 'error';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styles: ``,
})
export class ToastComponent {
  @Input() type: ToastType = 'success';
  @Input() message = '';
}
