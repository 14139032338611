const owsNgBase = 'https://data-ng-test.oseberg.io';
const owsBase = 'https://data-test.oseberg.io';
const esSearchBase = 'https://search2-test.oseberg.io';
const stateBase = 'https://atla-state-service-test.oseberg.io';

export const environment = {
  // Endpoints
  tokenEndpoint: 'https://auth-test.oseberg.io/v2/token',
  signOutEndpoint: 'https://auth-test.oseberg.io/sign-out',

  // Atla State Service
  searchUrl: `${stateBase}/search`,

  // Data
  esSearchBase: `${esSearchBase}`,
  sourceImageUrl: 'https://images-test.oseberg.io',

  // Local storage keys
  tokenKey: 'ose-token',
  refreshTokenKey: 'ose-refresh-token',
  usernameKey: 'ose-session-user',
  refreshExpiryKey: 'ose-refresh-expire',
  rememberedUserKey: 'ose-remembered-user',

  // Others
  refreshTokenExpireInterval: 24 * 14 * 60 * 60,
  appName: 'raven',
  env: 'dev',

  // Data
  legacyDbUrl: `${owsNgBase}/service/account/legacy-db-name`,
  cgiUrl: `${owsBase}/cgi-bin/oelite4.1.1/`,
  attachmentsUrl: `${owsNgBase}/service/attachments`,
  attachmentUrl: `${owsNgBase}/service/attachment`,
  maxEsRegionSetSize: 300,

  // Map
  maxRecordsAcrossAllLayersWarningThreshold: 250000,
  maxTotalMapLayers: 60,
  mapRecordsWarningCount: 100000,
  mixPanelToken: 'eff91cc576a2ffa4e9951ebb8f780065',
  shouldShowUnmappables: {
    minPercentCountyCoveredByAoi: 33,
  },
  map: {
    anchorSpritePath: 'assets/images/aoi-handle.png',
    aoiSearchEndpoint: `${owsNgBase}/service/oex/aoi`,
    countiesEndpoint: `${owsNgBase}/service/oex/counties`,
    legalsEndpoint: `${owsNgBase}/service/oex/referencelist`,
    featuresAt: { radius: 5 },
    pointLdPath: 'assets/images/mapIcons/point_16.png',
    pointHdPath: 'assets/images/mapIcons/point_32.png',
    spriteLdPath: 'assets/images/mapIcons/icons_16.png',
    spriteHdPath: 'assets/images/mapIcons/icons_32.png',
    wellSpriteLdPath: 'assets/images/mapIcons/well_icons_16.png',
    wellSpriteHdPath: 'assets/images/mapIcons/well_icons_32.png',
    defaultSpriteScale: 0.5,

    // Interaction
    propertyLabels: { default: 'name' },
    hoverTime: 0,

    // Mapstrap
    accessToken: 'pk.eyJ1Ijoib3NlYmVyZyIsImEiOiJZQ3RnMGRjIn0.YKUafz2SU4AnES4EZbwQ7A',
    container: 'map-container',
    interactive: true,
    hash: false,
    center: [-97.5, 35.3],
    zoom: 7.5,

    mapboxLayers: {
      terrain: /admin|state_label|country_label|aeroway|waterway|water|landcover|landuse|hillshade/,
      roads: /road|street|tunnel|bridge|path|contours|motorway/,
      labels: /label|poi/,
      counties: /counties/,
      landgrid: /section|township|abstract|block|survey/,
      pipelines: /pipeline/,
      basins: /basins/,
      ownership: /ownership/,
    },
    customSources: {},
    customLayers: [],
  },
};
