import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-section',
  templateUrl: './sidebar-section.component.html',
  styles: ``,
})
export class SidebarSectionComponent {
  @Input() title!: string;
  @Input() icon!: string;
}
