<div>
  <div class="flex items-center justify-between text-[#c2c3c4]">
    <div class="py-2 text-[16px] font-bold">{{ title }}</div>
    <i *ngIf="icon" class="fa fa-{{ icon }} cursor-pointer "></i>
  </div>

  <div class="px-2">
    <ng-content></ng-content>
  </div>
</div>
