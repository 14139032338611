import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarSectionComponent } from './components/sidebar-section/sidebar-section.component';
import { SidebarInnerSectionComponent } from './components/sidebar-inner-section/sidebar-inner-section.component';
import { PagePlaceholderComponent } from './components/page-placeholder/page-placeholder.component';
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
  declarations: [
    SidebarSectionComponent,
    SidebarInnerSectionComponent,
    PagePlaceholderComponent,
    ToastComponent,
  ],
  imports: [CommonModule],
  exports: [
    SidebarSectionComponent,
    SidebarInnerSectionComponent,
    PagePlaceholderComponent,
    ToastComponent,
  ],
})
export class SharedModule {}
