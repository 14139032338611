import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-placeholder',
  templateUrl: './page-placeholder.component.html',
  styles: ``,
})
export class PagePlaceholderComponent {
  @Input() icon = '';
  @Input() message = '';
}
